import { useApolloClient } from "@apollo/client/main.cjs";
import { Button, LegacyCard } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IPaymentMethod } from "@smartrr/shared/entities/PaymentMethod";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import { queryShopifyCustomer, queryShopifyCustomers } from "@smartrr/shared/shopifyGraphQL/customer";
import { debounce } from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
import { AutoCompleteWithTextField } from "@vendor-app/app/AdminRoute/components/elements/AutocompleteWithTextField";

import { ImportCustomerModal } from "../ImportCustomerModal";

export interface ICustomerAutocomplete {
  loadCustomerPaymentMethods: (relationshipId: string, shopifyId: string, custRelId: string) => void;
  setIsShippingAvailable: (isAvailable: boolean) => void;
  setFieldValue: (filedName: string, value: any) => void;
  updateShopifyPaymentMethodsState: (paymentMethods: IPaymentMethod[]) => void;
}

export function CustomerAutocomplete({
  loadCustomerPaymentMethods,
  setFieldValue,
  setIsShippingAvailable,
  updateShopifyPaymentMethodsState,
}: ICustomerAutocomplete): JSX.Element {
  const [autoCompleteCustomers, setAutoCompleteCustomers] = useState<ICustomerRelationshipShallow[]>([]);
  const [isModalOpen, openModal, closeModal] = useBoolean(false);
  const [isLoading, setLoading] = useState(false);
  const [isAvailableOnShopify, setAvailableOnShopify] = useState(false);
  const [hasResults, setHasResults] = useState(true);
  const [shopifyCustomerId, setShopifyCustomerId] = useState("");
  const client = useApolloClient();

  const options = useMemo(
    () =>
      autoCompleteCustomers
        .filter(el => el.email)
        .map(({ email, id }) => ({
          label: email,
          value: id,
        })),
    [autoCompleteCustomers]
  );

  const setFieldValues = <T, K extends keyof T>(objToUse: T, mapObject: Record<string, K>) => {
    for (const [fieldName, key] of Object.entries(mapObject)) {
      setFieldValue(fieldName, objToUse[key] || "");
    }
  };

  const setShippingInfo = async (customer?: ICustomerRelationshipShallow) => {
    if (customer) {
      setFieldValues(customer, {
        email: "email",
        firstName: "firstName",
        lastName: "lastName",
      });

      const res = await queryShopifyCustomer(customer.shopifyId!, client as any);

      if (res.type === "success") {
        const defaultAddress = res.body.data.customer?.defaultAddress;

        if (defaultAddress) {
          setFieldValues(defaultAddress, {
            address1: "address1",
            address2: "address2",
            zip: "zip",
            city: "city",
            province: "provinceCode",
            country: "countryCodeV2",
            phone: "phone",
          });
        }
      }

      setIsShippingAvailable(true);
    }
  };

  const onEmailSelect = (customerIds: string[]) => {
    const [selectedCustomerId] = customerIds;
    const customer = autoCompleteCustomers.find(customer => customer.id === selectedCustomerId);

    loadCustomerPaymentMethods(selectedCustomerId, customer?.shopifyId!, customer?.id!);
    setShippingInfo(customer);
  };

  const checkResultsOnShopify = async (email: string, shopCustomersCount: number) => {
    if (!email || shopCustomersCount) {
      return;
    }

    setHasResults(false);

    const resp = await queryShopifyCustomers(client, `email:${email}`, 1);

    if (resp.type === "success" && resp.body.data.customers.edges.length) {
      setAvailableOnShopify(true);
      setShopifyCustomerId(resp.body.data.customers.edges[0].node.id);
    } else {
      setAvailableOnShopify(false);
    }
  };

  const onEmailChange = useCallback(
    debounce(async (email: string) => {
      setIsShippingAvailable(false);
      setHasResults(true);

      if (!email) {
        setAutoCompleteCustomers([]);
        return;
      }

      setLoading(true);
      const res = await typedFrontendVendorApi.getReq("/customer-relationship", {
        query: {
          filterLike: {
            email,
          },
        },
      });

      if (res.type === "success") {
        const results = res.body.data;

        setAutoCompleteCustomers(results);

        if (results.length === 1 && results[0]!.email === email) {
          setShippingInfo(results[0]);
        }

        checkResultsOnShopify(email, results.length);
      }

      setLoading(false);
    }, 250),
    []
  );

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <div style={{ width: "0", height: "0" }} data-testid="customer-autocomplete" />
        <AutoCompleteWithTextField
          onSelect={onEmailSelect}
          onChange={onEmailChange}
          options={options}
          loading={isLoading}
          willLoadMoreResults
        />
        {!hasResults && (
          <Fragment>
            <Box mt={1} mb={+isAvailableOnShopify}>
              Seems like customer with provided email doesn{"'"}t exist in Smartrr{" "}
              {!isAvailableOnShopify && "and Shopify"}
            </Box>
            {!!isAvailableOnShopify && <Button onClick={openModal}>Import from Shopify</Button>}
          </Fragment>
        )}
      </LegacyCard.Section>
      <ImportCustomerModal
        open={isModalOpen}
        shopifyCustomerId={shopifyCustomerId}
        onClose={closeModal}
        setShippingInfo={setShippingInfo}
        updateShopifyPaymentMethodsState={updateShopifyPaymentMethodsState}
      />
    </LegacyCard>
  );
}
